<div class="relative ml-3">
  <!-- Profile Button -->
  <button
    (click)="toggleMenu()"
    aria-expanded="false"
    aria-haspopup="true"
    class="relative flex rounded-full bg-gray-800 text-sm"
    type="button">
    <span class="sr-only">Open user menu</span>
    <img alt="" class="h-9 w-9 rounded-md" clickOutside src="https://avatars.githubusercontent.com/u/6483181?v=4" />
  </button>
  <!-- Dropdown -->
  <div
    [ngClass]="
      isMenuOpen
        ? 'pointer-events-auto scale-100 animate-fade-in-up opacity-100 duration-200'
        : 'pointer-events-none scale-95 opacity-0 duration-100 ease-in'
    "
    class="absolute right-0 mt-2 w-60 origin-top-right transform rounded-md bg-white py-4 shadow-custom ring-1 ring-transparent ring-opacity-5 transition focus:outline-none dark:bg-night-600">
    <div class="flext-row flex items-center px-4 pb-4">
      <div class="w-10 shrink-0">
        <img alt="" class="rounded-md" src="https://avatars.githubusercontent.com/u/6483181?v=4" />
      </div>
      <div class="overflow-hidden px-2 text-sm font-semibold text-gray-700 dark:text-night-50">
        {{ user?.firstname }} {{ user?.lastname }}
        <p class="truncate text-ellipsis text-xs font-semibold text-gray-400 dark:text-night-300">{{ user?.email }}</p>
      </div>
    </div>

    <div class="border-b border-dashed border-gray-200 dark:border-night-500"></div>

    <ul class="my-2 mx-4">
      <li
        (click)="signOut()"
        class="block cursor-pointer rounded-md px-3 py-2 text-sm font-semibold text-gray-700 hover:bg-gray-100 hover:text-primary-500 dark:text-night-200 dark:hover:bg-night-500 dark:hover:text-primary-500"
        routerLink="/auth">
        Sign out
      </li>
    </ul>
  </div>
</div>
