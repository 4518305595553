<div class="date-range">
  <div class="mt-4">{{ weekStartDate | date: 'dd MMM' }} - {{ weekEndDate | date: 'dd MMM yyyy' }}</div>
</div>

<div class="week-cal">
  <button
    type="button"
    (click)="previousWeek()"
    class="font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center me-2">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke="currentColor"
      class="size-6">
      <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
    </svg>
  </button>
  <div class="week-days">
    <!-- [ngClass]="{ 'bg-blue-500 text-white': isSelectedDate(day.date), 'text-gray-500': !isSelectedDate(day.date) }" -->
    <div
      *ngFor="let day of weekDays"
      class="cursor-pointer p-2 rounded"
      [ngClass]="{ 'selected-date': isSelectedDate(day.date), '': !isSelectedDate(day.date) }"
      (click)="selectDate(day.date)">
      <p class="day-of-month">{{ day.dayOfMonth }}</p>
      <p class="day-name">{{ day.dayName }}</p>
    </div>
  </div>
  <button
    type="button"
    (click)="nextWeek()"
    class="font-medium rounded-full text-sm p-2.5 text-center inline-flex items-center me-2">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke="currentColor"
      class="size-6">
      <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
    </svg>
  </button>
</div>
