import { createSelector } from '@ngrx/store';
import { SelectedVenueState } from './selectedVenue.state';
import { selectDashboardState } from '../dashboard.selectors';

export const selectSelectedVenueState = createSelector(selectDashboardState, (state) => state.selectedVenue);

export const venue = createSelector(
  selectSelectedVenueState,
  (state: SelectedVenueState) => state.venue,
);

export const selectedVenueIsLoading = createSelector(
  selectSelectedVenueState,
  (state: SelectedVenueState) => state.loading,
);

export const selectedVenueError = createSelector(
  selectSelectedVenueState,
  (state: SelectedVenueState) => state.error,
);

export const selectedVenueLayoutUpdateSuccess = createSelector(
  selectSelectedVenueState,
  (state: SelectedVenueState) => state.success,
);

export const selectedVenueId = createSelector(
  selectSelectedVenueState,
  selectedVenueIsLoading,
  selectedVenueError,
  (state: SelectedVenueState) => state.venue?.id,
);
