<div
  [ngClass]="{ 'max-h-screen': submenu.expanded }"
  class="max-h-0 overflow-hidden pt-1 pl-4 transition-all duration-500">
  <ul class="flex flex-col border-l border-dashed border-gray-200 pl-2 text-night-200 dark:border-night-500">
    <li *ngFor="let sub of submenu.children">
      <div (click)="toggleMenu(sub)" class="flex rounded hover:bg-gray-100 dark:hover:bg-night-600">
        <!-- Condition -->
        <ng-container
          [ngTemplateOutletContext]="{ sub: sub }"
          [ngTemplateOutlet]="sub.children ? childMenu : parentMenu">
        </ng-container>

        <!-- Parent Menu -->
        <ng-template #parentMenu let-sub="sub">
          <a
            (click)="closeMobileMenu()"
            [routerLinkActiveOptions]="{ exact: true }"
            [routerLink]="sub.route"
            class="inline-block w-full px-4 py-2 text-xs font-semibold"
            routerLinkActive="text-primary-500">
            {{ sub.label }}
          </a>
        </ng-template>

        <!-- Child Menu -->
        <ng-template #childMenu let-sub="sub">
          <a class="inline-block w-full cursor-pointer px-4 py-2 text-xs font-semibold">
            {{ sub.label }}
          </a>
          <button
            [ngClass]="{ 'rotate-90': sub.expanded }"
            class="flex items-center p-1 text-gray-400 transition-all duration-500 dark:text-night-400">
            <svg-icon [svgClass]="'h-5 w-5'" src="assets/icons/heroicons/solid/chevron-right.svg"></svg-icon>
          </button>
        </ng-template>
      </div>
      <!-- Submenu items -->
      <app-navbar-mobile-submenu [submenu]="sub"></app-navbar-mobile-submenu>
    </li>
  </ul>
</div>
