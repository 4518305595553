import { buildFeatureState } from '@briebug/ngrx-auto-entity';
import { SelectedVenueSpotReservationStatusEntity } from './venue-spot-reservation-status.state.entity';
import { selectDashboardState } from '../dashboard.selectors';

const {
  actions,
  selectors,
  initialState,
  facade: ReservedSpotFacade,
  entityState,
  makeEntity,
} = buildFeatureState(SelectedVenueSpotReservationStatusEntity, 'dashboard', selectDashboardState);

export const venueReservedSpotState = {
  actions,
  selectors,
  initialState,
  entityState,
  makeEntity,
  facade: ReservedSpotFacade,
};
