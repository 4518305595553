import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { IAutoEntityService, IEntityInfo } from '@briebug/ngrx-auto-entity';
import { ApprovedVenueDto } from '@myspot-ui/domain';
import { ApprovedVenue } from '../modules/dashboard/+store/approvedVenue';

@Injectable()
export class ApprovedVenueService implements IAutoEntityService<ApprovedVenue> {

  private readonly basePath = 'venues';

  constructor(private readonly http: HttpClient) {
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  loadAll(entityInfo: IEntityInfo, criteria?: unknown): Observable<ApprovedVenue[]> {
    return this.http.get<ApprovedVenueDto[]>(`${environment.baseApiV1}/${this.basePath}/approved`) as Observable<ApprovedVenue[]>;
  }


}
