<div class="relative ml-3">
  <button
    (click)="toggleMenu()"
    aria-expanded="false"
    aria-haspopup="true"
    class="custom-vanue-button relative flex text-sm"
    type="button">
    <span *ngIf="!selectedVenue">Venues</span>
    <span *ngIf="selectedVenue">{{ selectedVenue.name }}</span>
  </button>
  <!-- Dropdown -->
  <div
    [ngClass]="
      isMenuOpen
        ? 'pointer-events-auto scale-100 animate-fade-in-up opacity-100 duration-200'
        : 'pointer-events-none scale-95 opacity-0 duration-100 ease-in'
    "
    class="absolute right-0 mt-2 w-60 origin-top-right transform rounded-md bg-white py-4 shadow-custom ring-1 ring-transparent ring-opacity-5 transition focus:outline-none dark:bg-night-600">
    <ul class="my-2 mx-4">
      <li
        (click)="selectVenue(venue)"
        *ngFor="let venue of venues"
        class="block cursor-pointer rounded-md px-3 py-2 text-sm font-semibold text-gray-700 hover:bg-gray-100 hover:text-primary-500 dark:text-night-200 dark:hover:bg-night-500 dark:hover:text-primary-500">
        {{ venue.name }}
      </li>
    </ul>
  </div>
</div>
