import { createReducer, on } from '@ngrx/store';
import {
  clearSelectedVenue,
  setSelectedVenue,
  updateSelectedVenueLayout,
  updateSelectedVenueLayoutFailure,
  updateSelectedVenueLayoutSuccess,
} from './selectedVenue.actions';
import { SelectedVenueState } from './selectedVenue.state';

export const initialSelectedVenueState: SelectedVenueState = {
  venue: null,
  error: null,
  loading: false,
  success: false,
};

export const selectedVenueReducer = createReducer(
  initialSelectedVenueState,
  on(setSelectedVenue, (state, { venue }) => ({ ...state, venue })),
  on(clearSelectedVenue, state => ({ ...state, venue: null })),
  on(updateSelectedVenueLayout, state => ({ ...state, loading: true, success: false, error: null })),
  on(updateSelectedVenueLayoutSuccess, state => ({ ...state, loading: false, success: true })),
  on(updateSelectedVenueLayoutFailure, (state, { error }) => ({ ...state, loading: false, error, success: false })),
);
