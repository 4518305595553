import { Entity, Key } from '@briebug/ngrx-auto-entity';

@Entity({ modelName: 'SelectedVenueElements' })
export class SelectedVenueElementStateEntity {
  @Key readonly id!: string;
  readonly type!: string;
  readonly width!: number;
  readonly height!: number;
  readonly startPositionX!: number;
  readonly startPositionY!: number;
  readonly spot!: {
    readonly label: string;
    readonly capacity: number;
    readonly costAmount: string;
    readonly disabled: boolean;
  } | null;
}
