import { Component, Input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { NgClass } from '@angular/common';
import { AuthTokenService } from '@myspot-ui/auth';
import { UserDto } from './user.dto';

@Component({
  selector: 'app-profile-menu',
  templateUrl: './profile-menu.component.html',
  styleUrls: ['./profile-menu.component.scss'],
  standalone: true,
  imports: [NgClass, RouterLink],
})
export class ProfileMenuComponent {
  public isMenuOpen = false;

  @Input() public user!: UserDto | null;

  constructor(private readonly authService: AuthTokenService) {
  }


  public toggleMenu(): void {
    this.isMenuOpen = !this.isMenuOpen;
  }

  public signOut() {
    this.authService.logout();
  }
}
