<ul class="mt-2 space-y-0.5 rounded-md bg-white py-3 shadow-custom dark:bg-night-600">
  <li *ngFor="let item of submenu" class="flex font-semibold text-gray-600 dark:text-night-200">
    <!-- Condition -->
    <ng-container
      [ngTemplateOutletContext]="{ item: item }"
      [ngTemplateOutlet]="item.children ? childMenu : parentMenu">
    </ng-container>

    <!-- Parent Menu -->
    <ng-template #parentMenu let-item="item">
      <button
        [routerLinkActiveOptions]="{ exact: true }"
        [routerLink]="item.route"
        class="mx-3 flex w-full items-center justify-between rounded-md py-2 px-2 text-xs font-semibold text-gray-600 hover:bg-gray-100 hover:text-primary-500 dark:text-night-200 dark:hover:bg-night-500"
        routerLinkActive="text-primary-500">
        <div class="flex items-center justify-start">
          <span *ngIf="item.icon" class="mr-2 text-gray-400 dark:text-night-200">
            <svg-icon [svgClass]="'h-5 w-5'" src="{{ item.icon }}"> </svg-icon>
          </span>
          <span class="ml-1">{{ item.label }}</span>
        </div>
      </button>
    </ng-template>

    <!-- Child Menu -->
    <ng-template #childMenu let-item="item">
      <div class="dropdown relative flex w-full">
        <button
          class="mx-3 flex w-full items-center justify-between rounded-md py-2 px-2 text-xs font-semibold text-gray-600 hover:bg-gray-100 hover:text-primary-500 dark:text-night-200 dark:hover:bg-night-500">
          <div class="flex items-center justify-start">
            <span *ngIf="item.icon" class="mr-2 text-gray-400 dark:text-night-200">
              <svg-icon [svgClass]="'h-5 w-5'" src="{{ item.icon }}"> </svg-icon>
            </span>
            <span class="ml-1">{{ item.label }}</span>
          </div>
          <span>
            <svg-icon [svgClass]="'h-5 w-5'" src="assets/icons/heroicons/solid/chevron-right.svg"> </svg-icon>
          </span>
        </button>
        <!-- Submenu Dropdown -->
        <div
          [submenu]="item.children"
          class="dropdown-content absolute top-0 left-[100%] min-w-[200px] origin-top-left"
          navbar-submenu></div>
      </div>
    </ng-template>
  </li>
</ul>
