import { Injectable } from '@angular/core';
import { createSelector, Store } from '@ngrx/store';
import { pendingVenueState } from './pendingVenueState';
import { PendingVenue } from './pendingVenue';
import { AppState } from '../../../../../+store';

@Injectable({
  providedIn: 'root',
})
export class PendingVenueFacade extends pendingVenueState.facade {

  constructor(private readonly store: Store<AppState>) {
    super(PendingVenue, store);
  }

  public readonly byId$ = (venueId: string) => this.store.select(createSelector(
    pendingVenueState.selectors.selectAll,
    venues => venues.find(venue => venue.id === venueId)));

}
