import { Component, Input } from '@angular/core';
import { NavbarMobileComponent } from './navbar-mobile/navbar-mobilecomponent';
import { ProfileMenuComponent } from './profile-menu/profile-menu.component';
import { NavbarMenuComponent } from './navbar-menu/navbar-menu.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { MenuService } from '../../services';
import { UserDto } from './profile-menu/user.dto';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  standalone: true,
  imports: [AngularSvgIconModule, NavbarMenuComponent, ProfileMenuComponent, NavbarMobileComponent],
})
export class NavbarComponent {

  @Input() public user!: UserDto | null;

  constructor(private menuService: MenuService) {
  }

  public toggleMobileMenu(): void {
    this.menuService.showMobileMenu = true;
  }
}
