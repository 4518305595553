import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { EMPTY, of, switchMap } from 'rxjs';
import {
  setSelectedVenue,
  updateSelectedVenueLayout,
  updateSelectedVenueLayoutFailure,
  updateSelectedVenueLayoutSuccess,
} from './selectedVenue.actions';
import { VenueLayoutService } from '../../../../services/venue-layout.service';
import { SelectedVenueFacade } from './selectedVenue.facade';
import { Clear, LoadAll } from '@briebug/ngrx-auto-entity';
import { SelectedVenueElementStateEntity } from '../selectedVenueElements';
// import { VenueSpotReservationStatus } from '../selectedVenueReservedSpots';
import moment from 'moment/moment';
import { SelectedVenueSpotReservationStatusEntity } from '../selectedVenueReservedSpots';

@Injectable()
export class SelectedVenueLayoutEffects {

  loadElementsOnVenueChange$ = createEffect(() => this.actions$.pipe(
    ofType(setSelectedVenue),
    switchMap((action) =>
      of(
        new Clear(SelectedVenueElementStateEntity),
        new Clear(SelectedVenueSpotReservationStatusEntity),
        new LoadAll(SelectedVenueElementStateEntity, { venueId: action.venue.id }),
        new LoadAll(SelectedVenueSpotReservationStatusEntity, {
          venueId: action.venue.id,
          startDate: moment().startOf('day'),
          endDate: moment().endOf('day'),
        }),
      ),
    ),
  ));
  private readonly selectedVenueId$ = this.selectedVenueFacade.selectedVenueId$;
  updateVenueLayout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateSelectedVenueLayout),
      withLatestFrom(this.selectedVenueId$),
      mergeMap(([action, venueId]) => {
        if (venueId === null) {
          return EMPTY;
        } else {
          // @ts-expect-error
          return this.venueMapLayoutService.saveLayout({ ...action.layout, venueId }).pipe(
            map(() => updateSelectedVenueLayoutSuccess()),
            catchError(error => of(updateSelectedVenueLayoutFailure({ error }))),
          );
        }
      }),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly venueMapLayoutService: VenueLayoutService,
    private readonly selectedVenueFacade: SelectedVenueFacade,
  ) {
  }
}
