import { createAction, props } from '@ngrx/store';
import { LoginResponseDto } from '../login.response.dto';
import { HttpErrorResponse } from '@angular/common/http';
import { LoginRequest } from '../login.request';
import { UserDto } from '@myspot-ui/base-ui';

export const loginUser = createAction('[User] Load User', props<LoginRequest>());
export const loginUserSuccess = createAction('[User] Load User Success', props<{ user: LoginResponseDto }>());
export const loginUserFailure = createAction('[User] Load User Failure', props<{ error: HttpErrorResponse }>());

export const loadUserProfile = createAction('[User] Load User Profile');
export const loadUserProfileSuccess = createAction('[User] Load User Profile Success', props<{ user: UserDto }>());
export const loadUserProfileFailure = createAction('[User] Load User Profile Failure', props<{
  error: HttpErrorResponse
}>());
