import { Action, createReducer, on } from '@ngrx/store';
import { LoginResponseDto } from '../login.response.dto';
import * as UserActions from './user.actions';
import { HttpErrorResponse } from '@angular/common/http';

export interface UserState {
  user: LoginResponseDto | null;
  error: HttpErrorResponse | null;
}

export const initialState: UserState = {
  user: null,
  error: null,
};

const reducer = createReducer(
  initialState,
  on(UserActions.loginUserSuccess, (state, { user }) => ({ ...state, user })),
  on(UserActions.loginUserFailure, (state, { error }) => ({ ...state, error })),
  on(UserActions.loadUserProfileSuccess, (state, { user }) => ({ ...state, user })),
  on(UserActions.loadUserProfileFailure, (state, { error }) => ({ ...state, error })),
);

export function userReducer(state: UserState | undefined, action: Action) {
  return reducer(state, action);
}
