import { buildFeatureState, IEntityState } from '@briebug/ngrx-auto-entity';
import { ApprovedVenue } from './approvedVenue';
import { selectDashboardState } from '../../dashboard.selectors';


const {
  actions,
  selectors,
  initialState,
  facade: ApprovedVenueFacade,
  entityState,
  makeEntity,
} = buildFeatureState(ApprovedVenue, 'dashboard', selectDashboardState);

export const approvedVenueState = {
  actions,
  selectors,
  initialState,
  entityState,
  makeEntity,
  facade: ApprovedVenueFacade,
};

export function approvedVenuesReducer(state = approvedVenueState.initialState): IEntityState<ApprovedVenue> {
  return state;
}
