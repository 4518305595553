import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BeachSpotReservationStatusResponseDto } from '../core/dto';
import { IAutoEntityService, IEntityInfo } from '@briebug/ngrx-auto-entity';
import { Observable } from 'rxjs';
import { Moment } from 'moment';
import { environment } from '../../environments/environment';
import { SelectedVenueSpotReservationStatusEntity } from '../modules/dashboard/+store';
import { BeachSpotInitializationRequestDto } from '@myspot-ui/domain';

@Injectable({
  providedIn: 'root',
})
export class BeachSpotService implements IAutoEntityService<SelectedVenueSpotReservationStatusEntity> {

  private readonly basePath = 'spots';

  constructor(private readonly http: HttpClient) {
  }

  loadAll(entityInfo: IEntityInfo, { venueId, startDate, endDate }: {
    venueId: string,
    startDate: Moment,
    endDate: Moment
  }): Observable<SelectedVenueSpotReservationStatusEntity[]> {
    return this.http.get<BeachSpotReservationStatusResponseDto[]>(`${environment.baseApiV1}/${this.basePath}/reserved`, {
      params: {
        venueId,
        startDate: startDate.format('YYYY-MM-DD'),
        endDate: endDate.format('YYYY-MM-DD'),
      },
    });
  }

  initiliazeSpot(spotId: string, data: BeachSpotInitializationRequestDto) {
    return this.http.post<void>(`${environment.baseApiV1}/${this.basePath}/${spotId}/initialize`, data);
  }
}
