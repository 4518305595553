import { Component, Input, OnInit } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { CommonModule, NgClass } from '@angular/common';


export interface ListItem {
  value: number | string | object;
  label: string;
}


@Component({
  selector: 'myspot-select-dropdown',
  templateUrl: './select-dropdown.component.html',
  standalone: true,
  animations: [
    trigger('opacityLeave', [
      transition(':leave', [
        style({ opacity: 1 }),
        animate('100ms ease-in', style({ opacity: 0 })),
      ]),
    ]),
  ],
  imports: [
    NgClass,
    CommonModule,
  ],
})
export class SelectDropdownComponent implements OnInit {
  @Input()
  items!: ListItem[] | null;
  @Input()
  placeholder: string = 'Select Plate Number';

  isSelect = false;

  current: ListItem = { value: '', label: this.placeholder };
  highlight: ListItem | undefined;

  constructor() {
  }

  ngOnInit(): void {
    if (this.items) {
      this.current = this.items[0];
    }
  }


  toggleSelect() {
    this.isSelect = !this.isSelect;
  }

  select(item: ListItem) {
    this.current = item;
    this.toggleSelect();
  }

  setHighlight(item: ListItem) {
    this.highlight = item;
  }
}
