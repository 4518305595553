import { AfterViewInit, Component, EventEmitter, Input, Output } from '@angular/core';
import { RouterLink } from '@angular/router';
import { CommonModule, NgClass } from '@angular/common';
import { ApprovedVenueDto } from '@myspot-ui/domain';
import { isNull } from 'lodash';

@Component({
  selector: 'myspot-ui-venue-dropdown',
  templateUrl: './venue-dropdown.component.html',
  styleUrls: ['./venue-dropdown.component.scss'],
  standalone: true,
  imports: [NgClass, RouterLink, CommonModule],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VenueDropdownComponent implements AfterViewInit {
  public isMenuOpen = false;

  @Input()
  venues!: ApprovedVenueDto[] | null;
  @Input()
  selectedVenue: ApprovedVenueDto | null = null;
  @Input()
  preSelectedVenue: ApprovedVenueDto | null = null;
  @Output()
  venueSelect: EventEmitter<ApprovedVenueDto> = new EventEmitter<ApprovedVenueDto>();

  ngAfterViewInit(): void {
    if (this.preSelectedVenue) {
      this.venueSelect.emit(this.preSelectedVenue);
    } else {
      if (!this.selectedVenue && !isNull(this.venues) && this.venues.length > 0) {
        this.venueSelect.emit(this.venues[0]);
      }
    }

  }

  selectVenue(event: ApprovedVenueDto) {
    this.venueSelect.emit(event);
    this.toggleMenu();
  }


  public toggleMenu(): void {
    this.isMenuOpen = !this.isMenuOpen;
  }

}
