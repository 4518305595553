import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AuthTokenService {

  private localStorageKey = 'jwtToken';

  constructor() {
  }

  // Save the JWT token in local storage or a cookie
  public saveToken(token: string | null, localStorageKey = 'jwtToken'): void {
    this.localStorageKey = localStorageKey;
    localStorage.setItem(localStorageKey, token || 'null');
  }

  // Check if the user is authenticated
  public isAuthenticated(): boolean {
    const token = localStorage.getItem(this.localStorageKey);
    // Implement a check for token validity if required
    return !!token;
  }

  public getToken(): string | null {
    return localStorage.getItem(this.localStorageKey);
  }

  // Remove the JWT token (e.g., on logout)
  public logout(): void {
    localStorage.removeItem(this.localStorageKey);
  }
}
