import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { UserDto } from '@myspot-ui/base-ui';

@Injectable({
  providedIn: 'root',
})
export class EmployeeService {

  private apiUrl = 'employees';

  constructor(private http: HttpClient) {
  }

  activate(activationToken: string, password: string): Observable<void> {
    const body = { activationToken: activationToken, password: password };
    return this.http.post<void>(`${environment.baseApiV1}/${this.apiUrl}/activate`, body);
  }

  loadUser(): Observable<UserDto> {
    return this.http.get<UserDto>(`${environment.baseApiV1}/${this.apiUrl}/profile`);
  }
}
