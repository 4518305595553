import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { filter, of, tap } from 'rxjs';
import * as UserActions from './user.actions';
import { AuthService } from '../../../../services/auth.service';
import { LoginResponseDto } from '../login.response.dto';
import { Router } from '@angular/router';
import { EmployeeService } from '../../../../services';

@Injectable()
export class UserEffects {
  loginUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.loginUser),
      switchMap(({ email, password }) =>
        this.authService.login({ email, password }).pipe(
          filter(value => value.body !== null),
          map(value => UserActions.loginUserSuccess(({ user: value.body as LoginResponseDto }))),
          catchError(error => of(UserActions.loginUserFailure({ error }))),
        ),
      ),
    ),
  );

  loginUserSuccess$ = createEffect(() =>
      this.actions$.pipe(
        ofType(UserActions.loginUserSuccess),
        tap(() => this.router.navigateByUrl('/dashboard')),
      ),
    { dispatch: false },  // This is important as we're not dispatching an action from this effect
  );

  loadUserProfile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.loadUserProfile),
      switchMap(() =>
        this.employeeService.loadUser().pipe(
          map(user => UserActions.loadUserProfileSuccess({ user })),
          catchError(error => of(UserActions.loadUserProfileFailure({ error }))),
        ),
      ),
    ),
  );

  constructor(private readonly actions$: Actions,
              private readonly authService: AuthService,
              private readonly router: Router,
              private readonly employeeService: EmployeeService) {
  }
}
