import { Inject, Injectable, OnDestroy, signal } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { MenuItem, SubMenuItem } from '../components';
import { APP_MENU_ITEMS } from './constants';

@Injectable()
export class MenuService implements OnDestroy {
  public readonly _pagesMenu = signal<MenuItem[]>([]);
  private _showSidebar = signal(true);
  private _subscription = new Subscription();

  constructor(private router: Router,
              @Inject(APP_MENU_ITEMS) private menuItems: MenuItem[]) {
    /** Set dynamic menu */
    this._pagesMenu.set(menuItems);

    let sub = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        /** Expand menu base on active route */
        this._pagesMenu().forEach((menu) => {
          let activeGroup = false;
          menu.items.forEach((subMenu) => {
            const active = this.isActive(subMenu.route);
            subMenu.expanded = active;
            subMenu.active = active;
            if (active) activeGroup = true;
            if (subMenu.children) {
              this.expand(subMenu.children);
            }
          });
          menu.active = activeGroup;
        });
      }
    });
    this._subscription.add(sub);
  }

  private _showMobileMenu = signal(false);

  get showMobileMenu() {
    return this._showMobileMenu();
  }

  set showMobileMenu(value: boolean) {
    this._showMobileMenu.set(value);
  }

  get showSideBar() {
    return this._showSidebar();
  }

  set showSideBar(value: boolean) {
    this._showSidebar.set(value);
  }

  get pagesMenu() {
    return this._pagesMenu();
  }

  public toggleSidebar() {
    this._showSidebar.set(!this._showSidebar());
  }

  public toggleMenu(menu: any) {
    this.showSideBar = true;
    menu.expanded = !menu.expanded;
  }

  public toggleSubMenu(submenu: SubMenuItem) {
    submenu.expanded = !submenu.expanded;
  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  private expand(items: Array<any>) {
    items.forEach((item) => {
      item.expanded = this.isActive(item.route);
      if (item.children) this.expand(item.children);
    });
  }

  private isActive(instruction: any): boolean {
    return this.router.isActive(this.router.createUrlTree([instruction]), {
      paths: 'subset',
      queryParams: 'subset',
      fragment: 'ignored',
      matrixParams: 'ignored',
    });
  }
}
