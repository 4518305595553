<div>
  <ng-content select="label"></ng-content>
  <div class="relative mt-2">
    <button (click)="toggleSelect()" aria-expanded="true"
            aria-haspopup="listbox"
            aria-labelledby="listbox-label" class="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6" type="button">
      <span class="block truncate">{{ current.label }}</span>
      <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
        <svg aria-hidden="true" class="h-5 w-5 text-gray-400" fill="currentColor" viewBox="0 0 20 20">
          <path clip-rule="evenodd"
                d="M10 3a.75.75 0 01.55.24l3.25 3.5a.75.75 0 11-1.1 1.02L10 4.852 7.3 7.76a.75.75 0 01-1.1-1.02l3.25-3.5A.75.75 0 0110 3zm-3.76 9.2a.75.75 0 011.06.04l2.7 2.908 2.7-2.908a.75.75 0 111.1 1.02l-3.25 3.5a.75.75 0 01-1.1 0l-3.25-3.5a.75.75 0 01.04-1.06z"
                fill-rule="evenodd" />
        </svg>
      </span>
    </button>

    <!--
      Select popover, show/hide based on select state.

      Entering: ""
        From: ""
        To: ""
      Leaving: "transition ease-in duration-100"
        From: "opacity-100"
        To: "opacity-0"
    -->
    <ul *ngIf='isSelect' @opacityLeave
        aria-activedescendant="listbox-option-3"
        aria-labelledby="listbox-label" class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm sm:leading-6" role="listbox" tabindex="-1">
      <!--
        Select option, manage highlight styles based on mouseenter/mouseleave and keyboard navigation.

        Highlighted: "bg-indigo-600 text-white", Not Highlighted: "text-gray-900"
      -->
      <ng-container *ngIf="items">
        <li (click)="select(item)" (mouseover)="setHighlight(item)" *ngFor="let item of items"
            [ngClass]="{
        'text-white': highlight?.value === item.value,
        'bg-primary-500': highlight?.value === item.value,
        'text-gray-900': highlight?.value !== item.value
        }"
            class="text-gray-900 relative cursor-default select-none py-2 pl-8 pr-4" id="listbox-option-0"
            role="option">
          <!-- Selected: "font-semibold", Not Selected: "font-normal" -->
          <span
            [ngClass]="{
          'font-semibold': current.value === item.value,
          'font-normal': current.value !== item.value
          }"
            class="font-normal block truncate">{{ item.label }}</span>

          <!--
            Checkmark, only display for selected option.

            Highlighted: "text-white", Not Highlighted: "text-indigo-600"
          -->
          <span *ngIf="item.value === current.value"
                [ngClass]="{
          'text-white': highlight?.value === item.value,
          'text-indigo-600': highlight?.value !== item.value
          }"
                class="text-indigo-600 absolute inset-y-0 left-0 flex items-center pl-1.5">
          <svg aria-hidden="true" class="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
            <path clip-rule="evenodd"
                  d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                  fill-rule="evenodd" />
          </svg>
        </span>
        </li>
      </ng-container>
    </ul>
  </div>
</div>
