import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { VenueLayoutSchemaDto } from '@myspot-ui/components-ui';
import { IAutoEntityService, IEntityInfo } from '@briebug/ngrx-auto-entity';
import { Observable } from 'rxjs';
import { SelectedVenueElementStateEntity } from '../modules/dashboard/+store';

@Injectable({
  providedIn: 'root',
})
export class VenueLayoutService implements IAutoEntityService<SelectedVenueElementStateEntity> {

  private readonly basePath = 'layouts';

  constructor(private readonly http: HttpClient) {
  }

  saveLayout(data: VenueLayoutSchemaDto) {
    return this.http.post<void>(`${environment.baseApiV1}/${this.basePath}`, data);
  }

  getLayoutCanvas(venueId: string) {
    return this.http.get<{ content: string }>(`${environment.baseApiV1}/${this.basePath}/${venueId}/canvas`);
  }

  loadAll(entityInfo: IEntityInfo, criteria: { venueId: string }): Observable<SelectedVenueElementStateEntity[]> {
    return this.http.get<SelectedVenueElementStateEntity[]>(`${environment.baseApiV1}/${this.basePath}/${criteria.venueId}/elements`);
  }
}
