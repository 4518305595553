// File: selected-venue.actions.ts
import { createAction, props } from '@ngrx/store';
import { VenueLayoutSchemaDto } from '@myspot-ui/components-ui';
import { HttpErrorResponse } from '@angular/common/http';
import { ApprovedVenue } from '../approvedVenue';

export const setSelectedVenue = createAction(
  '[Venue Dropdown] Set Selected Venue',
  props<{ venue: ApprovedVenue }>(),
);

export const clearSelectedVenue = createAction(
  '[Venue Dropdown] Clear Selected Venue',
);


export const updateSelectedVenueLayout = createAction(
  '[Venue Layout] Update Selected Venue Layout',
  props<{ layout: Partial<VenueLayoutSchemaDto> }>(),
);

export const updateSelectedVenueLayoutSuccess = createAction(
  '[Venue Layout] Update Selected Venue Layout Success',
);

export const updateSelectedVenueLayoutFailure = createAction(
  '[Venue Layout] Update Selected Venue Layout Failure',
  props<{ error: HttpErrorResponse }>(),
);
