<section class="fixed bottom-0 z-0 block w-full bg-white/50 shadow backdrop-blur-sm dark:bg-night-600/50 md:hidden">
  <div class="flex justify-between text-xs font-semibold text-gray-600 dark:text-night-300">
    <button
      class="flex w-full flex-col items-center justify-center pt-3 pb-3 text-center hover:text-gray-800 hover:dark:text-night-100">
      <svg-icon [svgClass]="'h-5 w-5'" src="assets/icons/heroicons/outline/bell.svg"></svg-icon>
      <span class="">Home</span>
    </button>
    <button
      class="flex w-full flex-col items-center justify-center pt-3 pb-3 text-center hover:text-gray-800 hover:dark:text-night-100">
      <svg-icon [svgClass]="'h-5 w-5'" src="assets/icons/heroicons/outline/bookmark.svg"></svg-icon>
      <span class="text-xs">Home</span>
    </button>
    <button
      class="flex w-full flex-col items-center justify-center pt-3 pb-3 text-center hover:text-gray-800 hover:dark:text-night-100">
      <svg-icon [svgClass]="'h-5 w-5'" src="assets/icons/heroicons/outline/chart-pie.svg"></svg-icon>
      <span class="text-xs">Home</span>
    </button>
    <button
      class="flex w-full flex-col items-center justify-center pt-3 pb-3 text-center hover:text-gray-800 hover:dark:text-night-100">
      <svg-icon [svgClass]="'h-5 w-5'" src="assets/icons/heroicons/outline/gift.svg"></svg-icon>
      <span class="text-xs">Home</span>
    </button>
    <button
      class="flex w-full flex-col items-center justify-center pt-3 pb-3 text-center hover:text-gray-800 hover:dark:text-night-100">
      <svg-icon [svgClass]="'h-5 w-5'" src="assets/icons/heroicons/outline/information-circle.svg"></svg-icon>
      <span class="text-xs">Home</span>
    </button>
  </div>
</section>
