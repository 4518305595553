<div *ngFor="let menu of menuService.pagesMenu" class="dropdown relative inline-block">
  <!-- Button -->
  <button
    [ngClass]="
      menu.selected || menu.active
        ? 'bg-primary-500 text-white'
        : 'text-gray-600 hover:bg-gray-100 hover:text-primary-500 dark:text-night-200 dark:hover:bg-night-600 '
    "
    class="mr-2 inline-flex rounded-md px-3 py-2 text-sm font-medium">
    <span>{{ menu.group }}</span>
  </button>
  <!-- Dropdown  -->
  <div
    [submenu]="menu.items"
    class="dropdown-content absolute top-[100%] min-w-[200px] origin-top-left"
    navbar-submenu></div>
</div>
