import { Injectable } from '@angular/core';
import { selectedVenueElementsState } from './selected-venue-elements.state';
import { Store } from '@ngrx/store';
import { AppState } from '../../../../+store';
import { SelectedVenueElementStateEntity } from './selected-venue-element.state.entity';

@Injectable({
  providedIn: 'root',
})
export class SelectedVenueElementsFacade extends selectedVenueElementsState.facade {

  constructor(store: Store<AppState>) {
    super(SelectedVenueElementStateEntity, store);
  }
}
