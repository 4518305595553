import { Entity, Key } from '@briebug/ngrx-auto-entity';
import { ApprovedVenueDto } from '@myspot-ui/domain';

@Entity({ modelName: 'ApprovedVenues' })
export class ApprovedVenue implements ApprovedVenueDto {
  @Key id!: string;
  type!: string;
  priceLevel!: string;
  name!: string;
  description!: string;
  contactEmail!: string;
  contactPhoneNumber!: string;
  keywords!: string[];
  amenities!: (string | null)[];
  coverImageUrl!: string;
  galleryImageUrls!: string[];
  openingHour!: string;
  closingHour!: string;
  acceptReservationsUntilHour!: string;
  reservationsValidUntilHour!: string;
  latitude!: string;
  longitude!: string;
  city!: string;
  bookmarked!: boolean;
}
