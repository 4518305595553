export interface LoginResponseDto {
  readonly id?: string;
  readonly email?: string;
  readonly firstname?: string;
  readonly lastname?: string;
  readonly role?: string;
  readonly employmentLocations?: string[];
  readonly permissions?: string[];
  readonly organisation?: {
    readonly id: string;
    readonly name: string;
  };
}
