import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { SelectedVenueState } from './selectedVenue.state';
import { clearSelectedVenue, setSelectedVenue, updateSelectedVenueLayout } from './selectedVenue.actions';
import {
  selectedVenueId,
  selectedVenueIsLoading,
  selectedVenueLayoutUpdateSuccess,
  venue,
} from './selectedVenue.selectors';
import { VenueLayoutSchemaDto } from '@myspot-ui/components-ui';
import { ApprovedVenue } from '../approvedVenue';

@Injectable({
  providedIn: 'root',
})
export class SelectedVenueFacade {
  readonly selectedVenue$: Observable<ApprovedVenue | null> = this.store.select(venue);
  readonly selectedVenueId$ = this.store.select(selectedVenueId);
  readonly selectedVenueLoading$ = this.store.select(selectedVenueIsLoading);
  readonly selectedVenueLayoutUpdateSuccess$ = this.store.select(selectedVenueLayoutUpdateSuccess);

  constructor(private store: Store<{ selectedVenue: SelectedVenueState }>) {
  }

  updateVenueLayout(layout: Partial<VenueLayoutSchemaDto>): void {
    this.store.dispatch(updateSelectedVenueLayout({ layout }));
  }

  setSelectedVenue(venue: ApprovedVenue): void {
    this.store.dispatch(setSelectedVenue({ venue }));
  }

  clearSelectedVenue(): void {
    this.store.dispatch(clearSelectedVenue());
  }
}
