import { createAction, props } from '@ngrx/store';
import { BeachSpotDto } from '@myspot-ui/domain';

export const setSelectedSpot = createAction(
  '[Beach Spot] Set Selected Spot',
  props<{ spot: BeachSpotDto }>(),
);

export const clearSelectedSpot = createAction(
  '[Beach Spot] Clear Selected Spot',
);

// TODO initialize action
// export const initializeSelectedSpot = createAction(
//   '[Beach Spot] Initialize Selected Spot',
//   props<{ spotId }>(),
// );
