import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UserState } from './user.reducer';

export const selectUserState = createFeatureSelector<UserState>('auth');

export const selectUser = createSelector(
  selectUserState,
  (state: UserState) => state.user,
);

export const selectUserEmployeeVenues = createSelector(selectUser, (user) => user?.employmentLocations as string[]);

export const selectUserError = createSelector(
  selectUserState,
  (state: UserState) => state.error,
);
