import { venueReservedSpotState } from './venue-reserved-spots.state';
import { Injectable } from '@angular/core';
import { SelectedVenueSpotReservationStatusEntity } from './venue-spot-reservation-status.state.entity';
import { AppState } from '../../../../+store';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class VenueReservedSpotFacade extends venueReservedSpotState.facade {

  constructor(store: Store<AppState>) {
    super(SelectedVenueSpotReservationStatusEntity, store);
  }
}
