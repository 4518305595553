import { Action, createReducer, on } from '@ngrx/store';
import { SelectedSpotState } from './selectedSpot.state';
import { clearSelectedSpot, setSelectedSpot } from './selectedSpot.actions';

export const initialSelectedVenueState: SelectedSpotState = {
  selectedSpot: null,
  error: null,
  loading: false,
  success: false,
};

const reducer = createReducer(
  initialSelectedVenueState,
  on(setSelectedSpot, (state, { spot }) => ({ ...state, selectedSpot: spot })),
  on(clearSelectedSpot, state => ({ ...state, selectedSpot: null })),
);

export function selectedSpotReducer(state: SelectedSpotState | undefined, action: Action) {
  return reducer(state, action);
}
