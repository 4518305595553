import { Component, OnInit } from '@angular/core';
import { ThemeService } from '@myspot-ui/theme';
import { initFlowbite } from 'flowbite';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'My Spot';

  constructor(public themeService: ThemeService) {
  }

  ngOnInit(): void {
    initFlowbite();
  }
}
