import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IAutoEntityService, IEntityInfo } from '@briebug/ngrx-auto-entity';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { PendingVenueUpdateRequest } from '../core/dto';
import { PendingVenue } from '../modules/dashboard/+store/pendingVenue';

@Injectable()
export class PendingVenueService implements IAutoEntityService<PendingVenue> {

  readonly basePath = 'venues';

  constructor(private readonly http: HttpClient) {
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  loadAll(entityInfo: IEntityInfo, criteria?: unknown): Observable<PendingVenue[]> {
    return this.http.get<PendingVenue[]>(`${environment.baseApiV1}/${this.basePath}/pending-approval`);
  }

  updateVenue(data: PendingVenueUpdateRequest) {
    const contentTypeFieldMapping = {
      type: 'application/json',
      priceLevel: 'application/json',
      name: 'text/plain',
      description: 'text/plain',
      contactEmail: 'text/plain',
      contactPhoneNumber: 'text/plain',
      keywords: 'application/json',
      amenities: 'application/json',
      openingHour: 'application/json',
      closingHour: 'application/json',
      acceptReservationsUntilHour: 'application/json',
      reservationsValidUntilHour: 'application/json',
      city: 'text/plain',
      latitude: 'application/json',
      longitude: 'application/json',
      imagesToBeDeleted: 'application/json',
    };

    const body = new FormData();
    const keys: string[] = Object.keys(contentTypeFieldMapping);

    keys.forEach(key => {
      // @ts-expect-error
      if (data[key]) {
        // @ts-expect-error
        body.append(key, new Blob([(contentTypeFieldMapping[key] === 'application/json') ? JSON.stringify(data[key]) : data[key]], { type: contentTypeFieldMapping[key] }));
      }
    });

    data.images.galleryImages.forEach(file => body.append('galleryImages', file));
    body.append('coverImage', data.images.coverImage);

    return this.http.patch(`${environment.baseApiV1}/${this.basePath}/pending-approval/${data.id}`, body);
  }
}
