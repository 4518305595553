import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { BeachSpotDto } from '@myspot-ui/domain';
import { selectedSpot, selectedSpotIsLoading } from './selectedSpot.selectors';
import { SelectedSpotState } from './selectedSpot.state';
import { clearSelectedSpot, setSelectedSpot } from './selectedSpot.actions';

@Injectable({
  providedIn: 'root',
})
export class SelectedSpotFacade {
  readonly selectedSpot$: Observable<BeachSpotDto | null> = this.store.select(selectedSpot);
  readonly selectedSpotLoading$ = this.store.select(selectedSpotIsLoading);

  constructor(private store: Store<{ selectedVenue: SelectedSpotState }>) {
  }


  setSelectedSpot(spot: BeachSpotDto): void {
    this.store.dispatch(setSelectedSpot({ spot }));
  }

  clearSelectedSpot(): void {
    this.store.dispatch(clearSelectedSpot());
  }
}
