import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[myspotUiFullscreenOverlay]',
  standalone: true,
})
export class FullscreenOverlayDirective {
  private isLoading = false;
  private message = '';

  constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef) {
  }

  @Input() set appLoadingOverlay(loading: boolean) {
    this.isLoading = loading;
    this.updateView();
  }

  @Input() set appLoadingOverlayMessage(message: string) {
    this.message = message;
    this.updateView();
  }

  private updateView() {
    if (this.isLoading) {
      this.viewContainer.clear();
      // const view = this.viewContainer.createEmbeddedView(this.templateRef);
      // view.rootNodes[0].querySelector('.loading-message').textContent = this.message;
    } else {
      this.viewContainer.clear();
    }
  }
}
