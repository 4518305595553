import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { tap } from 'rxjs';
import { AuthTokenService } from '@myspot-ui/auth';
import { LoginRequest } from '../core/domain/user/login.request';
import { LoginResponseDto } from '../core/domain';

@Injectable({
  providedIn: 'root',
})
export class AuthService {

  private readonly basePath = '';

  constructor(private readonly http: HttpClient,
              private readonly authTokenService: AuthTokenService) {
  }

  login({ email, password }: LoginRequest) {
    // TODO save UserDataSomeWhere
    return this.http.post<LoginResponseDto>(`${environment.baseApiV1}/login/dashboard`, {
      email,
      password,
    }, { observe: 'response' })
      .pipe(
        tap(response => this.authTokenService.saveToken(response.headers.get('x-identity-token'))),
      );
  }
}
