<div class="relative bg-primary-500 dark:bg-night-700">
  <div class="mx-auto px-5">
    <div class="flex items-center justify-between py-3.5 md:justify-start">
      <!-- Mobile Navigation Menu Button-->
      <div class="sm:order-1 md:hidden">
        <button
          (click)="toggleMobileMenu()"
          aria-expanded="false"
          class="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500 hover:bg-gray-100 hover:text-gray-500 dark:bg-night-700 dark:text-night-200 dark:hover:text-night-400"
          type="button">
          <span class="sr-only">Open menu</span>
          <!-- Heroicon name: outline/menu -->
          <svg-icon [svgClass]="'h-6 w-6'" src="assets/icons/heroicons/outline/menu.svg"></svg-icon>
        </button>
      </div>

      <!-- Logo -->
      <div class="flex items-center justify-start sm:order-2 md:mr-10 lg:hidden">
        <a class="flex items-center justify-center rounded bg-primary-500 p-2 focus:outline-none focus:ring-1">
          <svg-icon src="assets/icons/logo.svg"> [svgClass]="'h-5 w-5'"></svg-icon>
        </a>
        <!--        <b class="hidden pl-3 text-sm font-bold dark:text-night-50 sm:block"> My Spot </b>-->
      </div>

      <!-- Desktop Menu -->
      <div class="hidden space-x-10 sm:order-3 md:flex">
        <app-navbar-menu></app-navbar-menu>
      </div>
      <!-- Profile menu -->
      <div class="items-center justify-end sm:order-4 md:flex md:flex-1 lg:w-0">
        <div class="mr-10">
          <!-- Content Projection -->
          <ng-content></ng-content>
        </div>
        <app-profile-menu [user]="user"></app-profile-menu>
      </div>
    </div>
  </div>
  <!-- Mobile menu -->
  <app-navbar-mobile></app-navbar-mobile>
</div>
