import { buildFeatureState, IEntityState } from '@briebug/ngrx-auto-entity';
import { PendingVenue } from './pendingVenue';
import { selectDashboardState } from '../../dashboard.selectors';

const {
  actions,
  selectors,
  initialState,
  facade: SelectedVenueElementFacade,
  entityState,
  makeEntity,
}
  = buildFeatureState(PendingVenue, 'dashboard', selectDashboardState);

export const pendingVenueState = {
  actions,
  selectors,
  initialState,
  entityState,
  makeEntity,
  facade: SelectedVenueElementFacade,
};

export function pendingVenuesReducer(state = pendingVenueState.initialState): IEntityState<PendingVenue> {
  return state;
}
