import { Action, ActionReducerMap } from '@ngrx/store';
import { IEntityState } from '@briebug/ngrx-auto-entity';
import { PendingVenue, pendingVenuesReducer } from './pendingVenue';
import { ApprovedVenue, approvedVenuesReducer } from './approvedVenue';
import { selectedVenueReducer, SelectedVenueState } from './selectedVenue';
import { selectedVenueElementsReducer, SelectedVenueElementStateEntity } from './selectedVenueElements';
import { selectedSpotReducer, SelectedSpotState } from '../pages/venue-view/+store/selectedSpot';
import { SelectedVenueSpotReservationStatusEntity, venueReservedSpotReducer } from './selectedVenueReservedSpots';

export interface DashboardState {
  approvedVenues: IEntityState<ApprovedVenue>;
  pendingVenues: IEntityState<PendingVenue>;
  selectedVenue: SelectedVenueState;
  selectedVenueElements: IEntityState<SelectedVenueElementStateEntity>;
  selectedVenueSpotsReservationStatus: IEntityState<SelectedVenueSpotReservationStatusEntity>;
  selectedSpot: SelectedSpotState;
}

export const DASHBOARD_FEATURE_STATE_NAME = 'dashboard';

export const dashboardReducers: ActionReducerMap<DashboardState, Action> = {
  approvedVenues: approvedVenuesReducer,
  pendingVenues: pendingVenuesReducer,
  selectedVenue: selectedVenueReducer,
  selectedVenueElements: selectedVenueElementsReducer,
  selectedVenueSpotsReservationStatus: venueReservedSpotReducer,
  selectedSpot: selectedSpotReducer,
};
