<nav
  [ngClass]="menuService.showSideBar ? 'w-52 xl:w-64' : 'w-[70px]'"
  class="scrollbar-thumb-rounded scrollbar-track-rounded hidden h-full flex-col justify-between overflow-auto bg-white transition-all duration-300 scrollbar-thin scrollbar-track-gray-50 scrollbar-thumb-gray-100 dark:bg-night-700 dark:scrollbar-thumb-night-500 dark:scrollbar-track-night-600 lg:flex">
  <div>
    <!-- Logo -->
    <div class="nav-logo bg-primary-500 dark:bg-night-700 px-3 py-3">
      <div *ngIf="menuService.showSideBar" class="flex items-center">
        <a
          class="flex items-center justify-center rounded bg-primary-500 dark:bg-night-700 p-2 focus:outline-none focus:ring-1">
          <svg-icon src="assets/icons/logo.svg"></svg-icon>
        </a>
      </div>

      <button
        type="button"
        (click)="toggleSidebar()"
        [ngClass]="{ 'rotate-180': !menuService.showSideBar }"
        class="text-whitefont-medium rounded-full text-sm p-2.5 text-center inline-flex items-center">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="size-6">
          <path
            fill-rule="evenodd"
            d="M7.72 12.53a.75.75 0 0 1 0-1.06l7.5-7.5a.75.75 0 1 1 1.06 1.06L9.31 12l6.97 6.97a.75.75 0 1 1-1.06 1.06l-7.5-7.5Z"
            clip-rule="evenodd" />
        </svg>
      </button>
    </div>

    <!-- Menu Items -->
    <div class="px-4">
      <app-sidebar-menu></app-sidebar-menu>
    </div>
  </div>

  <div class="mx-4 my-4 space-y-1">
    <!-- Dark Theme -->
    <div
      (click)="toggleTheme()"
      class="group flex h-9 cursor-pointer items-center justify-start rounded p-2 hover:bg-gray-100 dark:hover:bg-night-600">
      <svg-icon
        [src]="
          themeService.isDark ? 'assets/icons/heroicons/outline/sun.svg' : 'assets/icons/heroicons/outline/moon.svg'
        "
        [svgClass]="'h-5 w-5 text-gray-400 dark:text-night-400'">
      </svg-icon>

      <a class="ml-3 truncate text-xs font-semibold tracking-wide text-gray-600 focus:outline-none dark:text-night-100">
        {{ themeService.isDark ? 'Light Theme' : 'Dark Theme' }}
      </a>

      <div *ngIf="!menuService.showSideBar" class="fixed w-full">
        <span
          class="z-1 absolute left-12 -top-4 w-auto min-w-max origin-left scale-0 rounded-md bg-black p-2 text-xs font-bold text-white shadow-md transition-all duration-200 group-hover:scale-100 dark:bg-white dark:text-night-900">
          Theme
        </span>
      </div>
    </div>
    <!-- Sign out -->
    <div
      class="group flex h-9 cursor-pointer items-center justify-start rounded p-2 hover:bg-gray-100 dark:hover:bg-night-600">
      <svg-icon
        [svgClass]="'h-5 w-5 text-gray-400 dark:text-night-400'"
        src="assets/icons/heroicons/outline/logout.svg">
      </svg-icon>

      <a
        class="ml-3 truncate text-xs font-semibold tracking-wide text-gray-600 focus:outline-none dark:text-night-100"
        routerLink="/auth">
        Sign out
      </a>

      <div *ngIf="!menuService.showSideBar" class="fixed w-full">
        <span
          class="z-1 absolute left-12 -top-4 w-auto min-w-max origin-left scale-0 rounded-md bg-black p-2 text-xs font-bold text-white shadow-md transition-all duration-200 group-hover:scale-100 dark:bg-white dark:text-night-900">
          Sign out
        </span>
      </div>
    </div>
    <!-- Version -->
    <div
      class="group flex h-9 cursor-pointer items-center justify-start rounded p-2 hover:bg-gray-100 dark:hover:bg-night-600">
      <svg-icon
        [svgClass]="'h-5 w-5 text-gray-400 dark:text-night-400'"
        src="assets/icons/heroicons/outline/information-circle.svg">
      </svg-icon>

      <a
        class="ml-3 truncate text-[10px] font-semibold tracking-wide text-gray-600 focus:outline-none dark:text-night-100"
        href="https://github.com/luciano-work/angular-tailwind"
        target="_blank">
        <!--        <span class="rounded-lg bg-yellow-400/10 px-2 font-semibold text-yellow-500">v{{ appJson.version }}</span>-->
      </a>

      <div *ngIf="!menuService.showSideBar" class="fixed w-full">
        <span
          class="z-1 absolute left-12 -top-4 w-auto min-w-max origin-left scale-0 rounded-md bg-black p-2 text-xs font-bold text-white shadow-md transition-all duration-200 group-hover:scale-100 dark:bg-white dark:text-night-900">
          <!--          v{{ appJson.version }}-->
        </span>
      </div>
    </div>
  </div>
</nav>
