import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'myspot-ui-slide-over-right',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './slide-over-right.component.html',
  styleUrl: './slide-over-right.component.scss',
  animations: [
    trigger('translateX', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('500ms ease-in-out', style({ transform: 'translateX(0)' })),
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0)' }),
        animate('500ms ease-in-out', style({ transform: 'translateX(100%)' })),
      ]),
    ]),
  ],
})
export class SlideOverRightComponent {

  @Input()
  isSlideOver?: boolean | null;
  @Output()
  isSlideOverChange = new EventEmitter<boolean>();

  constructor() {
  }

  toggleSlideOver() {
    this.isSlideOver = !this.isSlideOver;
    this.isSlideOverChange.emit(this.isSlideOver);
  }
}
