import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { AuthTokenService } from './auth-token.service';

export const authGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthTokenService);
  const router = inject(Router);
  if (authService.isAuthenticated()) {
    return true;
  } else {
    authService.logout();
    // Navigate to the login page or show an error
    return router.navigate(['auth', 'login'], { queryParams: { returnUrl: state.url } }).then(value => false);
  }
};
