import { buildFeatureState } from '@briebug/ngrx-auto-entity';
import { SelectedVenueElementStateEntity } from './selected-venue-element.state.entity';
import { createFeatureSelector } from '@ngrx/store';
import { DashboardState } from '../dashboard.reducers';
import { selectDashboardState } from '../dashboard.selectors';

const {
  actions,
  selectors,
  initialState,
  facade: facade,
  entityState,
  makeEntity,
} = buildFeatureState(SelectedVenueElementStateEntity, 'dashboard', selectDashboardState);

export const selectedVenueElements = createFeatureSelector<DashboardState>(
  'selectedVenueElements',
);

export const selectedVenueElementsState = {
  actions,
  selectors,
  initialState,
  entityState,
  makeEntity,
  facade,
};
