import { createSelector } from '@ngrx/store';
import { SelectedSpotState } from './selectedSpot.state';
import { selectDashboardState } from '../../../../+store/dashboard.selectors';
import { DashboardState } from '../../../../+store';

export const selectSelectedSpotState = createSelector(selectDashboardState, (state: DashboardState) => state.selectedSpot);

export const selectedSpot = createSelector(
  selectSelectedSpotState,
  (state: SelectedSpotState) => state.selectedSpot,
);

export const selectedSpotIsLoading = createSelector(
  selectSelectedSpotState,
  (state: SelectedSpotState) => state.loading,
);

export const selectedVenueError = createSelector(
  selectSelectedSpotState,
  (state: SelectedSpotState) => state.error,
);
