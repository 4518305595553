import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { loadUserProfile, loginUser } from './user.actions';
import { LoginRequest } from '../login.request';
import { selectUser } from './user.selectors';
import { AppState } from '../../../../+store';

@Injectable({
  providedIn: 'root',
})
export class UserFacade {

  public readonly user$ = this.store.select(selectUser);

  constructor(private readonly store: Store<AppState>) {
  }

  loginUser({ email, password }: LoginRequest) {
    this.store.dispatch(loginUser({ email, password }));
  }

  loadUserProfile() {
    this.store.dispatch(loadUserProfile());
  }
}
