import { Entity, Key } from '@briebug/ngrx-auto-entity';

@Entity({ modelName: 'PendingVenues' })
export class PendingVenue {
  @Key id!: string;
  type!: string;
  priceLevel!: string;
  name!: string;
  description!: string;
  contactEmail!: string;
  contactPhoneNumber!: string;
  keywords!: string[];
  amenities!: string[];
  coverImageUrl!: string;
  galleryImageUrls!: string[];
  openingHour!: string;
  closingHour!: string;
  acceptReservationsUntilHour!: string;
  reservationsValidUntilHour!: string;
  latitude!: string;
  longitude!: string;
  city!: string;
  createdAt!: number;
  lastUpdatedAt!: number;
}
