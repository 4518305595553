<div class="flex h-16 items-center bg-white dark:bg-night-700">
  <div class="flex w-full items-center justify-between px-7 text-[13px] font-medium">
    <div class="text-gray-400 dark:text-night-300">
      <span class="mr-2">{{ year }}©</span>
      <a
        class="font-normal text-gray-800 hover:text-primary-500 dark:text-night-100 dark:hover:text-primary-500"
        href="https://myspot.bg/"
        target="_blank">
        My Spot
      </a>
    </div>
  </div>
</div>
