import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { addDays, startOfWeek, endOfWeek, isSameDay } from 'date-fns';

@Component({
  selector: 'app-week-calendar',
  templateUrl: './week-calendar.component.html',
  standalone: true,
  styleUrls: ['./week-calendar.component.scss'],
  imports: [CommonModule],
})
export class WeekCalendarComponent implements OnInit {
  currentDate: Date = new Date();
  weekStartDate: Date = new Date();
  weekEndDate: Date = new Date();
  selectedDate: Date = new Date(); // Set to today's date initially
  weekDays: { dayName: string, dayOfMonth: number, date: Date }[] = [];

  ngOnInit() {
    this.setWeekDates();
    this.selectToday();
  }

  setWeekDates() {
    this.weekStartDate = startOfWeek(this.currentDate, { weekStartsOn: 0 }); // Sunday
    this.weekEndDate = endOfWeek(this.currentDate, { weekStartsOn: 0 }); // Saturday
    this.generateWeekDays();
  }

  generateWeekDays() {
    this.weekDays = [];
    for (let i = 0; i < 7; i++) {
      const date = addDays(this.weekStartDate, i);
      this.weekDays.push({
        dayName: this.getDayName(date.getDay()),
        dayOfMonth: date.getDate(),
        date: date
      });
    }
  }

  getDayName(dayIndex: number): string {
    const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    return dayNames[dayIndex];
  }

  previousWeek() {
    this.currentDate = addDays(this.weekStartDate, -1);
    this.setWeekDates();
  }

  nextWeek() {
    this.currentDate = addDays(this.weekEndDate, 1);
    this.setWeekDates();
  }

  selectDate(date: Date) {
    this.selectedDate = date;
  }

  selectToday() {
    this.selectedDate = new Date(); // Set selectedDate to today's date
  }

  isSelectedDate(date: Date): boolean {
    return isSameDay(this.selectedDate, date);
  }
}