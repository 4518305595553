import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule, NgClass } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { ResponsiveHelperComponent } from '@myspot-ui/base-ui';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { authTokenInterceptor } from '@myspot-ui/auth';
import { NgrxAutoEntityModule } from '@briebug/ngrx-auto-entity';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { ApprovedVenueService } from './services';
import { VenueLayoutService } from './services/venue-layout.service';
import { dashboardAppEffects } from './+store/app.effects';
import {
  ApprovedVenue,
  SelectedVenueElementStateEntity,
  SelectedVenueSpotReservationStatusEntity,
} from './modules/dashboard/+store';
import { appReducer } from './+store';
import { PendingVenue } from './modules/dashboard/+store/pendingVenue';
import { PendingVenueService } from './services/pending-venue.service';
import { BeachSpotService } from './services/beach-spot.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NgClass,
    RouterOutlet,
    ResponsiveHelperComponent,
    CommonModule,
    AppRoutingModule,
    StoreModule.forRoot(appReducer),
    EffectsModule.forRoot(dashboardAppEffects),
    NgrxAutoEntityModule.forRoot(),
    environment.production
      ? []
      : StoreDevtoolsModule.instrument(),
  ],
  providers: [
    provideHttpClient(withInterceptors([authTokenInterceptor])),
    { provide: ApprovedVenue, useClass: ApprovedVenueService },
    { provide: PendingVenue, useClass: PendingVenueService },
    { provide: SelectedVenueElementStateEntity, useClass: VenueLayoutService },
    { provide: SelectedVenueSpotReservationStatusEntity, useClass: BeachSpotService },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
